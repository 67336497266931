import { doc, onSnapshot } from "firebase/firestore";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import ReactGA from "react-ga4";

import type { PublicTenantConfig } from "practicare/types/tenant.model";
import { db, firebaseConfig } from "../config/firebase";

interface TenantContextValue {
  tenantSettings: PublicTenantConfig;
  isTenantLoading: boolean;
  sendGAEvent: (eventName: string) => void;
}

const defaultTenantSettings: PublicTenantConfig = {
  defaultLang: "pl",
  companyLogoUrl: "",
  companyWebsiteUrl: "",
  welcomeMessage: "Witamy w PractiCare! 👋",
  websiteTitle: "",
  faviconUrl: "",
  loginImageUrl: "",
  allowSkipPaymentInForm: false,
  gaId: "",
  isDemo: false,
  defaultCurrency: "PLN",
  widget: {
    splitOnlineAndOfflineServices: false,
    renderTherapyTypes: false,
    therapyTypesLabel: "",
    addOnlineToLocations: false,
    minimumHoursBeforeBooking: 24,
    buttonColor: "",
    fontColor: "",
  },
};

const TenantContext = createContext<TenantContextValue | undefined>(undefined);

const TenantProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [tenantSettings, setTenantSettings] = useState<PublicTenantConfig>(
    defaultTenantSettings
  );
  const [isTenantLoading, setIsTenantLoading] = useState<boolean>(true);

  const sendGAEvent = (eventName: string) => {
    if (tenantSettings.gaId) {
      ReactGA.event({
        category: "PractiCare",
        action: eventName,
      });
    }
  };

  useEffect(() => {
    const unsub = onSnapshot(
      doc(db, "config", "publicTenantSettings"),
      (doc) => {
        if (doc.exists()) {
          const data = doc.data() as PublicTenantConfig;
          setTenantSettings({ ...data, loading: false } as any);
          document.title = data.websiteTitle + " by PractiCare";
          if (data.faviconUrl) {
            let link: any = document.querySelector("link[rel~='icon']");
            if (!link) {
              link = document.createElement("link");
              link.rel = "icon";
              document.head.appendChild(link);
            }
            link.href = data.faviconUrl;
          }
          if (data.gaId && tenantSettings.gaId !== data.gaId) {
            ReactGA.initialize([
              { trackingId: firebaseConfig.measurementId },
              { trackingId: data.gaId },
            ]);
          } else {
            ReactGA.initialize(firebaseConfig.measurementId);
          }
          setIsTenantLoading(false);
        }
      }
    );

    return () => unsub();
  }, []);

  return (
    <TenantContext.Provider
      value={{ tenantSettings, isTenantLoading, sendGAEvent }}
    >
      {children}
    </TenantContext.Provider>
  );
};

const useTenant = (): TenantContextValue => {
  const context = useContext(TenantContext);
  if (!context) {
    throw new Error("useTenant must be used within a TenantProvider");
  }
  return context;
};

export { TenantProvider, useTenant };
