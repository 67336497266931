import { Fragment, lazy } from "react";
import { Navigate } from "react-router-dom";
import { isObjEmpty } from "../config/utils";
import BlankLayout from "../layout/blankLayout";
import LayoutWrapper from "../layout/components/layout-wrapper";
import PublicRoute from "../layout/components/routes/PublicRoute";
import VerticalLayout from "../layout/vertical";

const getLayout: any = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
};

const DefaultRoute = "/login";

const Login = lazy(() => import("../pages/login"));
const Holidays = lazy(() => import("../pages/holidays"));
const Customers = lazy(() => import("../pages/customers"));
const Locations = lazy(() => import("../pages/settings/locations"));
const Rooms = lazy(() => import("../pages/settings/rooms"));
const Problems = lazy(() => import("../pages/settings/problems"));
const Sources = lazy(() => import("../pages/settings/sources"));
const Types = lazy(() => import("../pages/settings/types"));
const Services = lazy(() => import("../pages/settings/services"));
const Variants = lazy(() => import("../pages/settings/variants"));
const Users = lazy(() => import("../pages/settings/users"));
const Appointments = lazy(() => import("../pages/appointments"));
const Payments = lazy(() => import("../pages/payments"));
const Invoices = lazy(() => import("../pages/invoices"));
const TimeTable = lazy(() => import("../pages/timeTable"));
const UserDetails = lazy(() => import("../pages/user"));
const CustomerDetails = lazy(() => import("../pages/customers/details"));
const Calendar = lazy(() => import("../pages/calendar"));
const CalendarLocationView = lazy(
  () => import("../pages/calendar/locationView")
);
const AppointmentDetails = lazy(() => import("../pages/appointments/details"));
const Payroll = lazy(() => import("../pages/payroll"));
const GeneralSettings = lazy(() => import("../pages/settings/general"));
const Registration = lazy(() => import("../pages/registration"));
const Payment = lazy(() => import("../pages/payment"));
const PaymentConfirmation = lazy(() => import("../pages/paymentConfirmation"));
const RegistrationConfirmation = lazy(
  () => import("../pages/registration/confirmation")
);
const ResetPassword = lazy(() => import("../pages/login/passwordReset"));
const PasswordForgot = lazy(() => import("../pages/login/passwordForgot"));
const LoginWithEmail = lazy(() => import("../pages/login/loginWithEmail"));
const LoginWithToken = lazy(() => import("../pages/login/loginWithToken"));
const CalendarUserView = lazy(() => import("../pages/calendar/userView"));
const MyCalendarAdminView = lazy(
  () => import("../pages/calendar/userViewForAdmin")
);
const Feed = lazy(() => import("../pages/feed"));
const Messages = lazy(() => import("../pages/messaging"));
const Coupons = lazy(() => import("../pages/settings/coupons"));
const Marketplace = lazy(() => import("../pages/settings/marketplace"));
const FAQ = lazy(() => import("../pages/faq"));
// ** Merge Routes
const Routes = [
  {
    path: "/",
    index: true,
    element: <Navigate replace to={DefaultRoute} />,
  },
  {
    path: "/login",
    element: <Login />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/holidays",
    element: <Holidays />,
    meta: {},
  },
  {
    path: "/messages",
    element: <Messages />,
    meta: {},
  },
  {
    path: "/customers",
    element: <Customers />,
    meta: {},
  },
  {
    path: "/locations",
    element: <Locations />,
    meta: {},
  },
  {
    path: "/rooms",
    element: <Rooms />,
    meta: {},
  },
  {
    path: "/problems",
    element: <Problems />,
    meta: {},
  },
  {
    path: "/sources",
    element: <Sources />,
    meta: {},
  },
  {
    path: "/types",
    element: <Types />,
    meta: {},
  },
  {
    path: "/services",
    element: <Services />,
    meta: {},
  },
  {
    path: "/coupons",
    element: <Coupons />,
    meta: {},
  },
  {
    path: "/variants/:serviceId",
    element: <Variants />,
    meta: {},
  },
  {
    path: "/users",
    element: <Users />,
    meta: {},
  },
  {
    path: "/appointments",
    element: <Appointments />,
    meta: {},
  },
  {
    path: "/appointments/:id",
    element: <AppointmentDetails />,
    meta: {},
  },
  {
    path: "/payments",
    element: <Payments />,
    meta: {},
  },
  {
    path: "/paymentConfirmation",
    element: <PaymentConfirmation />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/RegistrationConfirmation",
    element: <RegistrationConfirmation />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/invoices",
    element: <Invoices />,
    meta: {},
  },
  {
    path: "/timeTable",
    element: <TimeTable />,
    meta: {},
  },
  {
    path: "/users/:id",
    element: <UserDetails />,
    meta: {},
  },
  {
    path: "/me",
    element: <UserDetails />,
    meta: {},
  },
  {
    path: "/customers/:id",
    element: <CustomerDetails />,
    meta: {},
  },
  {
    path: "/calendar",
    element: <Calendar />,
    meta: {},
  },
  {
    path: "/payroll",
    element: <Payroll />,
    meta: {},
  },
  {
    path: "/settings",
    element: <GeneralSettings />,
    meta: {},
  },
  {
    path: "/register",
    element: <Registration />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/payment",
    element: <Payment />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/resetPassword",
    element: <ResetPassword />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/passwordForgot",
    element: <PasswordForgot />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/loginWithEmail",
    element: <LoginWithEmail />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/loginWithToken",
    element: <LoginWithToken />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/myCalendar",
    element: <CalendarUserView />,
    meta: {},
  },
  {
    path: "/calendarLocation",
    element: <CalendarLocationView />,
    meta: {},
  },
  {
    path: "/myCalendarAdminView",
    element: <MyCalendarAdminView />,
    meta: {},
  },
  {
    path: "/feed",
    element: <Feed />,
    meta: {},
  },
  {
    path: "/faq",
    element: <FAQ />,
    meta: {},
  },
  {
    path: "/start",
    element: <FAQ />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/marketplace",
    element: <Marketplace />,
    meta: {},
  },
];

const getRouteMeta = (route: any) => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta };
    } else {
      return {};
    }
  }
};

// ** Return Filtered Array of Routes & Paths
const mergeLayoutRoutes = (layout: any, defaultLayout: any) => {
  const LayoutRoutes: any[] = [];

  if (Routes) {
    Routes.filter((route) => {
      let isBlank = false;
      // ** Checks if Route layout or Default layout matches current layout
      if (
        (route.meta && route.meta.layout && route.meta.layout === layout) ||
        ((route.meta === undefined || route.meta.layout === undefined) &&
          defaultLayout === layout)
      ) {
        const RouteTag = PublicRoute;

        // ** Check for public or private route
        if (route.meta) {
          route.meta.layout === "blank" ? (isBlank = true) : (isBlank = false);
        }
        if (route.element) {
          const Wrapper =
            isObjEmpty(route.element.props) && isBlank === false
              ? LayoutWrapper
              : Fragment;

          route.element = (
            <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          );
        }

        // Push route to LayoutRoutes
        LayoutRoutes.push(route);
      }
      return LayoutRoutes;
    });
  }
  return LayoutRoutes;
};

const getRoutes = (layout: any) => {
  const defaultLayout = layout || "vertical";
  const layouts = ["vertical", "blank"];

  const AllRoutes: any[] = [];

  layouts.forEach((layoutItem) => {
    const LayoutRoutes = mergeLayoutRoutes(layoutItem, defaultLayout);

    AllRoutes.push({
      path: "/",
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes,
    });
  });
  return AllRoutes;
};

export { DefaultRoute, getRoutes, Routes };
